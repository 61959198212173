import { SparklesIcon } from '@heroicons/react/24/outline';
import { useLoaderData } from '@remix-run/react';
import { useState } from 'react';
import SwiperBundleMinCss from 'swiper/swiper-bundle.min.css';

import { Button, ButtonInner, Modal } from 'aslan';
import { useT } from 'i18n';
import { StoryBuilder } from 'leap-stories';

import { onboardingUrl } from '~/helpers/routes';
import { i18n } from '~/i18n.server';
import { playProduct } from '~/services/leapProduct';
import miniCourseSwiperJsCss from '~/styles/mini-course-swiper-js.css';

import type {
  HeadersFunction,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction } from
'@remix-run/cloudflare';

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) return [];
  return data.meta;
};

export const links: LinksFunction = () => {
  return [
  {
    rel: 'stylesheet',
    href: SwiperBundleMinCss
  },
  { rel: 'stylesheet', href: miniCourseSwiperJsCss }];

};

export const headers: HeadersFunction = () => {
  // The following header is aggresively caching this page.
  // It means the browser will use the cached version for 1 minutes
  // and the CDN will use the cached version for 1 hour.
  // The stale-while-revalidate means that if the CDN cache is expired
  // it will serve the stale version in the following day while it revalidates the cache.
  // This is useful for pages that are not updated often, which should be the case for this page.
  // This should guarantee that the www ungated experience loads fast for the users.
  // In case we need to force revalidation, we can purge https://try.theleap.co/product-builder
  // from Cloudflare's CDN Cache: https://developers.cloudflare.com/cache/how-to/purge-cache/purge-by-single-file/
  return {
    'Cache-Control':
    'public, max-age=60, s-maxage=3600, stale-while-revalidate=86400'
  };
};

export async function loader(args: LoaderFunctionArgs) {
  const t = await i18n.getFixedT(args.request);
  const product = await playProduct(args);

  return {
    meta: [
    { title: t('productBuilder.meta.title') },
    {
      name: 'description',
      content: t('productBuilder.meta.description')
    }],

    cards: product?.productData?.course?.cards,
    ctaUrl: onboardingUrl(args)
  };
}

function CtaButton() {
  const t = useT('translation', 'productBuilder');
  const { ctaUrl } = useLoaderData<typeof loader>();
  return (
    <div className="flex flex-col justify-between space-y-2">
      {/* target is needed so when used in an iframe it will
        tell the browser to navigate the top window instead of the iframe */}
      <a href={ctaUrl} target="_top" className="w-full">
        <ButtonInner color="yellow" size="base" isRounded isFullWidth isOutline>
          {t('cta.signup')}
        </ButtonInner>
      </a>
    </div>);

}

export default function ProductBuilder() {
  const t = useT('translation', 'productBuilder');
  const { cards } = useLoaderData<typeof loader>();
  const [openCta, setOpenCta] = useState(false);

  return (
    <div className="h-full w-full bg-gray-100">
      <div className="flex flex-col justify-center items-center h-full scale-[.70]">
        <StoryBuilder
          onUpload={() => {
            setOpenCta(true);
          }}
          actions={
          <Button
            color="yellow"
            isRounded
            size="base"
            onClick={() => setOpenCta(true)}>

              {t('generateWithAi')}
              <SparklesIcon className="h-6 w-6 ml-2" aria-hidden />
            </Button>}

          hideMiniPreview
          hideTips
          showPreview
          initialSlide={1}
          cards={cards}
          slidesPerView="auto"
          className="h-[700px] md:h-[700px] mt-0 w-[140%]"
          storyPlayerClassName="scale-[.70]" />

        <Modal title={t('modal.title')} open={openCta} setOpen={setOpenCta}>
          <p>
            <CtaButton />
          </p>
        </Modal>
      </div>
    </div>);

}